import {
  BIEvent,
  IFrameEvent,
  Interaction,
  ProfileActionType,
  Thunk,
  ThunkDispatch,
  ThunkExtra,
} from '../../types';
import { Applications } from '../../services/public-api-store';
import { openProfilePreviewNotification } from '../../services/modal';
import { getSetIsProfilePreviewAction } from '../actions';
import { emitBIEventWithPayload } from '../../services/bi-event';

const toggleProfilePreviewInMenu = async (
  getPublicAPI: ThunkExtra['getPublicAPI'],
  shouldEnterPreview: boolean,
) => {
  const membersAreaAPI = await getPublicAPI(Applications.MembersArea);
  return shouldEnterPreview
    ? membersAreaAPI?.enterPublicProfilePreviewMode()
    : membersAreaAPI?.leavePublicProfilePreviewMode();
};

const openPublicProfilePreviewNotification = (
  dispatch: ThunkDispatch,
  {
    compId,
    flowAPI,
    wixCodeApi,
    platformAPIs,
    dataSyncService,
    getPublicAPI,
  }: ThunkExtra,
) => {
  const onNotificationClose = async () => {
    await toggleProfilePreviewInMenu(getPublicAPI, false);
    dataSyncService.addIFrameEvent(IFrameEvent.LeavePublicProfilePreview);
    dispatch(getSetIsProfilePreviewAction(false));
  };

  openProfilePreviewNotification({
    compId,
    wixCodeApi,
    platformAPIs,
    isPublic: true,
    isMobile: flowAPI.environment.isMobile,
    onClose: onNotificationClose,
  });
};

export const togglePublicProfilePreview: Thunk = () => async (
  dispatch,
  getState,
  extra,
) => {
  const { getPublicAPI, dataSyncService, monitoringService } = extra;

  const handlePublicProfileToggle = async () => {
    await toggleProfilePreviewInMenu(getPublicAPI, true);
    dataSyncService.addIFrameEvent(IFrameEvent.EnterPublicProfilePreview);
    dispatch(getSetIsProfilePreviewAction(true));
    openPublicProfilePreviewNotification(dispatch, extra);
    emitBIEventWithPayload({
      state: getState(),
      extra,
      biEvent: BIEvent.RoleActionClicked,
      payload: {
        action_type: ProfileActionType.VIEW_PUBLIC_PROFILE,
      },
    });
  };

  await monitoringService.toMonitored(
    Interaction.EnterPublicProfilePreview,
    handlePublicProfileToggle(),
  );
};

export const openPrivateProfilePreviewNotification: Thunk = () => async (
  _,
  __,
  { compId, flowAPI, wixCodeApi, platformAPIs },
) => {
  openProfilePreviewNotification({
    compId,
    wixCodeApi,
    platformAPIs,
    isPublic: false,
    isMobile: flowAPI.environment.isMobile,
  });
};
