import { PrivacyStatus, RoleId } from '@wix/members-domain-ts';

import {
  InjectedComputedProps,
  Nullable,
  AdditionalAction,
  AdditionalActionId,
  AdditionalActionName,
  DataHook,
  NumberStyleParam,
  PublicMember,
  ProfileLayout,
  Experiments,
} from '../types';
import {
  aboutAppDefinitionId,
  myAccountAppDefinitionId,
} from '../constants/app-definition-id';
import { FlowAPI, WixExperiments } from '../types/controller';
import { RootState } from './root-reducer';
import {
  defaultProfileImageLayout,
  defaultProfileLayout,
} from '../components/ProfileCard/stylesParams';
import { getBadgeSettingsProps } from '../services/badge-layout';

export const isInPage = (flowAPI: FlowAPI, applicationId: string) => {
  const { currentPage } = flowAPI.controllerConfig.wixCodeApi.site;
  return currentPage?.applicationId === applicationId;
};

const isInSocialCommunity = (state: RootState) => {
  return getInCommunity(state) && state.site.isSocial;
};

export const getIsOwnProfile = ({ users: { viewed, current } }: RootState) => {
  return viewed.uid === current?.uid;
};

const getStyleParams = ({ componentSettings: { styleParams } }: RootState) => {
  return styleParams;
};

const getProfileLayout = (state: RootState, flowAPI: FlowAPI) => {
  if (flowAPI.environment.isEditorX) {
    return ProfileLayout.FullWidth;
  }

  const { numbers } = getStyleParams(state);
  const profileLayout = numbers[NumberStyleParam.ProfileLayout];

  return profileLayout ?? defaultProfileLayout;
};

const getProfileStyle = (state: RootState) => {
  const pictureStyle = getStyleParams(state).numbers.pictureStyle;
  return pictureStyle ?? defaultProfileImageLayout;
};

const showAllBadges = ({ site, users }: RootState) => {
  const badgesTabOpened = site.settingsTab === DataHook.BadgesTabButton;
  const hasBadgesAssigned = users.viewed.badges.length;

  return badgesTabOpened && !hasBadgesAssigned;
};

const getAssignedBadges = (state: RootState) => {
  const { users, badges } = state;
  const allBadges = badges.list;
  const viewedMemberBadges = users.viewed.badges;

  if (showAllBadges(state)) {
    return allBadges;
  }

  return allBadges.filter(({ id }) => {
    return viewedMemberBadges.includes(id);
  });
};

const getBadgeSettings = (
  language: string,
  state: RootState,
): InjectedComputedProps['badgesSettings'] => {
  const { componentSettings, globalSettings } = state;

  return {
    ...getBadgeSettingsProps(componentSettings, globalSettings),
    align: 'center',
    badges: getAssignedBadges(state),
    language,
    maxRows: 1,
    useTextRemainder: false,
  };
};

const getInCommunity = ({ users }: RootState) =>
  isMemberInCommunity(users.viewed);

export const isCustomProfileEnabled = (
  { componentSettings }: RootState,
  experiments: WixExperiments,
) => {
  const { booleans } = componentSettings.styleParams;
  const customProfileSettingEnabled = booleans.customProfileEnabled ?? false;

  return (
    customProfileSettingEnabled &&
    experiments.enabled(Experiments.ViewerCustomProfile)
  );
};

const shouldHideEditRoleAction = (
  flowAPI: FlowAPI,
  state: RootState,
  experiments: WixExperiments,
) => {
  return isCustomProfileEnabled(state, experiments)
    ? isInPage(flowAPI, myAccountAppDefinitionId)
    : isInSocialCommunity(state);
};

const getRolesActions = (
  flowAPI: FlowAPI,
  state: RootState,
  experiments: WixExperiments,
) => {
  const { users, roles } = state;
  const { rolesActions } = users.viewed;
  const memberRoleActions = rolesActions ?? [];
  const shouldHideEdit = shouldHideEditRoleAction(flowAPI, state, experiments);

  const filteredRoleActions = memberRoleActions
    .filter((action) => !shouldHideEdit || action.roleId !== RoleId.EDIT)
    .filter((action) => action.roleId !== RoleId.JOIN_COMMUNITY);

  return roles.map
    ? filteredRoleActions.map((action) => ({
        ...action,
        ...roles.map?.[action.roleId],
      }))
    : filteredRoleActions;
};

const getAdditionalActions = (
  flowAPI: FlowAPI,
  state: RootState,
  experiments: WixExperiments,
) => {
  const additionalActions: AdditionalAction[] = [];

  if (!isCustomProfileEnabled(state, experiments)) {
    return additionalActions;
  }

  const _isInSocialCommunity = isInSocialCommunity(state);
  const _getIsOwnProfile = getIsOwnProfile(state);
  const isOwnSocialProfile = _isInSocialCommunity && _getIsOwnProfile;
  const shouldAddViewPublicProfileAction =
    flowAPI.environment.isMobile &&
    isOwnSocialProfile &&
    isInPage(flowAPI, aboutAppDefinitionId);

  if (isOwnSocialProfile) {
    additionalActions.push({
      id: AdditionalActionId.ShareProfile,
      action: AdditionalActionName.ShareProfile,
    });
  }

  if (shouldAddViewPublicProfileAction) {
    additionalActions.push({
      id: AdditionalActionId.ViewPublicProfile,
      action: AdditionalActionName.ViewPublicProfile,
    });
  }

  return additionalActions;
};

export const isMemberInCommunity = (member: Nullable<PublicMember>) => {
  if (!member) {
    return false;
  }

  const inCommunityStatuses = [PrivacyStatus.Public, PrivacyStatus.Unknown];

  return inCommunityStatuses.includes(member.privacyStatus);
};

const getIsDesignPreview = ({ site }: RootState) => {
  return site.settingsTab === DataHook.DesignTabButton;
};

const getIsResponsiveEditor = (flowAPI: FlowAPI) => {
  return flowAPI.environment.isEditorX;
};

const getShowCover = (state: RootState) => {
  return getStyleParams(state).booleans.showCover ?? false;
};

const getShowAsBlocked = ({ users }: RootState) => {
  const { current, viewed } = users;

  return (
    !!viewed.uid &&
    current?.uid !== viewed.uid &&
    viewed.roles.includes(RoleId.BLOCK_MEMBER)
  );
};

const hasBlogWriterProfileRoles = (roles: RoleId[]) => {
  const blogWriterProfileRoles = [
    RoleId.ADMIN,
    RoleId.SET_BLOG_WRITER,
    RoleId.SET_BLOG_EDITOR,
  ];

  return blogWriterProfileRoles.some((role) => roles.includes(role));
};

export const isBlogWriterOrEditor = ({ site, users }: RootState) => {
  const { installedApps } = site;
  const isBlogInstalled = installedApps.wixBlog ?? false;

  return isBlogInstalled && hasBlogWriterProfileRoles(users.viewed.roles);
};

const getShowTitle = (state: RootState, experiments: WixExperiments) => {
  return (
    isCustomProfileEnabled(state, experiments) || isBlogWriterOrEditor(state)
  );
};

const isFollowersInstalled = ({ site }: RootState) => {
  const { installedApps } = site;
  return !!installedApps.membersFF;
};

const showViewPublicProfileCTA = (
  state: RootState,
  flowAPI: FlowAPI,
  experiments: WixExperiments,
) => {
  return (
    isCustomProfileEnabled(state, experiments) &&
    isInPage(flowAPI, aboutAppDefinitionId) &&
    isMemberInCommunity(state.users.viewed)
  );
};

const showEditProfileCTA = (
  state: RootState,
  flowAPI: FlowAPI,
  experiments: WixExperiments,
) => {
  if (!isCustomProfileEnabled(state, experiments)) {
    return true;
  }

  return (
    isInPage(flowAPI, aboutAppDefinitionId) &&
    !isMemberInCommunity(state.users.viewed)
  );
};

const getCanEdit = ({ users }: RootState) =>
  users.viewed.rolesActions?.some(({ roleId }) => roleId === RoleId.EDIT) ??
  false;

const getAllowChat = (flowAPI: FlowAPI, state: RootState) => {
  const { booleans } = getStyleParams(state);
  const showMessageButtonParam = flowAPI.environment.isMobile
    ? booleans.showMessageButtonMobile
    : booleans.showMessageButton;
  const showMessageButton = showMessageButtonParam ?? true;

  return showMessageButton && state.site.isSocialChat;
};

const getIsInProfilePage = (
  state: RootState,
  flowAPI: FlowAPI,
  experiments: WixExperiments,
) => {
  return (
    !isCustomProfileEnabled(state, experiments) ||
    isInPage(flowAPI, aboutAppDefinitionId)
  );
};

export const getComputedProps = (
  flowAPI: FlowAPI,
  state: RootState,
  experiments: WixExperiments,
): InjectedComputedProps => ({
  profileLayout: getProfileLayout(state, flowAPI),
  pictureStyle: getProfileStyle(state),
  badgesSettings: getBadgeSettings(flowAPI.environment.language, state),
  rolesActions: getRolesActions(flowAPI, state, experiments),
  additionalActions: getAdditionalActions(flowAPI, state, experiments),
  inCommunity: getInCommunity(state),
  isDesignPreview: getIsDesignPreview(state),
  isResponsiveEditor: getIsResponsiveEditor(flowAPI),
  isInProfilePage: getIsInProfilePage(state, flowAPI, experiments),
  showAsBlocked: getShowAsBlocked(state),
  showCover: getShowCover(state),
  showTitle: getShowTitle(state, experiments),
  showViewPublicProfileCTA: showViewPublicProfileCTA(
    state,
    flowAPI,
    experiments,
  ),
  showEditProfileCTA: showEditProfileCTA(state, flowAPI, experiments),
  canEdit: getCanEdit(state),
  allowChat: getAllowChat(flowAPI, state),
  followersInstalled: isFollowersInstalled(state),
  isCustomProfileEnabled: isCustomProfileEnabled(state, experiments),
});
