import { RoleId } from '@wix/members-domain-ts';

import { origin } from '../../../constants/bi';
import { ThunkWithArgs } from '../../../types';
import { openModalWithCallback } from '../../../services/modal';
import { getSetViewedMemberAction } from '../../actions';
import { scheduleViewedMemberSync, clearInitialDataCache } from '../common';

type OppositeRolesMap = { [key in RoleId]?: RoleId };

const oppositeRolesMap: OppositeRolesMap = {
  [RoleId.BLOCK_MEMBER]: RoleId.UNBLOCK_MEMBER,
  [RoleId.SET_FORUM_MODERATOR]: RoleId.UNSET_FORUM_MODERATOR,
  [RoleId.SET_BLOG_WRITER]: RoleId.UNSET_BLOG_WRITER,
};

const rolesIdsWithPictures = [
  RoleId.SET_FORUM_MODERATOR,
  RoleId.UNSET_FORUM_MODERATOR,
  RoleId.SET_BLOG_WRITER,
  RoleId.UNSET_BLOG_WRITER,
];

export const modalRoleAction: ThunkWithArgs<RoleId> = (roleId) => (
  dispatch,
  getState,
  extra,
) => {
  const { compId, wixCodeApi, platformAPIs, membersService } = extra;
  const state = getState();
  const { viewed } = state.users;
  const applicableRoleId = viewed.roles.includes(roleId)
    ? oppositeRolesMap[roleId] ?? roleId
    : roleId;
  const hasPicture = rolesIdsWithPictures.includes(roleId);

  const payload = {
    ...(hasPicture && {
      memberName: viewed.name,
      memberPicture: viewed.picture,
    }),
    originalAppComponent: origin,
  };

  const onConfirm = async () => {
    const { uid, roles } = viewed;
    const viewMode = wixCodeApi.window.viewMode;
    const options = { uid, roles, roleId, viewMode };

    await membersService.applyRoleAction(options);
    clearInitialDataCache(state, extra);
    const updatedMember = await membersService.getMember(viewed.uid);

    scheduleViewedMemberSync(extra);
    dispatch(getSetViewedMemberAction(updatedMember));
  };

  openModalWithCallback({
    compId,
    modalType: applicableRoleId,
    payload,
    platformAPIs,
    wixCodeApi,
    onConfirm,
  });
};
